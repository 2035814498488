import { useSpring, animated } from "@react-spring/web";
import Logo from "./assets/logos/Discourse-logo-final.png";
import Text from "./assets/logos/ISCOURSE.png";

// rgb(65, 168, 244)

function GradientMorph() {
  const styles = useSpring({
    from: { background: "linear-gradient(45deg,blue, red)" },
    to: async (next, cancel) => {
      await next({
        background: "linear-gradient(45deg, red, blue)",
      });
      await next({
        background: "linear-gradient(45deg, rgb(65, 168, 244), red)",
      });
      await next({
        background: "linear-gradient(45deg, rgb(65, 168, 244), red)",
      });
      await next({
        background: "linear-gradient(45deg, blue,red)",
      });
    },
    loop: true,
    config: { duration: 5000 },
  });

  return (
    <animated.div
      style={{ ...styles, width: 2320, height: 1400, position: "relative" }}
    >
      <div
        style={{
          paddingTop: 20,
          paddingLeft: 2200,
          color: "whitesmoke",
          fontSize: 20,
        }}
      >
        <div href="test.com">join the waitlist</div>
      </div>

      <div
        style={{
          paddingTop: 20,
          paddingLeft: 2200,
          color: "whitesmoke",
          fontSize: 20,
        }}
      >
        BETA LAUNCH DATE: 7/19
      </div>

      <img
        src={Logo}
        alt="Descriptive Alt Text"
        style={{
          width: "17%",
          height: "28%",
          objectFit: "cover",
          position: "absolute",
          top: "51%",
          left: "22%",
          transform: "translate(-50%, -50%)", // Centers the logo
        }}
      />
      <img
        src={Text}
        alt="Descriptive Alt Text"
        style={{
          width: "60%",
          height: "20%",
          objectFit: "cover",
          position: "absolute",
          top: "50%",
          left: "60%",
          transform: "translate(-50%, -50%)", // Centers the logo
        }}
      />

      <div
        style={{
          paddingTop: 1000,
          paddingLeft: 1000,
          color: "whitesmoke",
          fontSize: 20,
        }}
      >
        <i>
          be
          <b> seen</b>, be
          <b> heard</b>
        </i>
      </div>
    </animated.div>
  );
}

function App() {
  return (
    <div>
      <GradientMorph />
    </div>
  );
}

export default App;
